import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeTodo } from "../features/todoFeatures/todoSlice";
import './RemoveTodo.module.css';
import { useEffect, useState } from "react";


    
const RemoveTodo = () => {
    const dispatch = useDispatch();
    const todos = useSelector(state => state.list);
    
    console.log(todos[0]);
    // const storedTodos = JSON.parse(localStorage.getItem('todos'));
    const reversedTodos = todos.slice().reverse();
    console.log(reversedTodos);

    


    return (
        <div >
            <h2>Todos</h2>
            <ul >
            {reversedTodos.length === 0 ? <h4>Your Todo List Is Empty</h4> : reversedTodos.map(todo => (
                 
                <div key={todo.id} style={{borderRadius: "0.4em", marginBottom: "4px", background: "#6161FF", color: "white", fontFamily: "sans-serif",marginRight: "38px",  border: "2px black solid"}}>{todo.text}<span><button onClick={() => dispatch(removeTodo(todo.id))} style={{background: "#FFDAB9", marginLeft: "80px", borderRadius: "0.4em"}}>Remove</button></span> </div>
                
            ))}
            </ul>
        </div>
    )
}

export default RemoveTodo;