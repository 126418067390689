import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTodo } from "../features/todoFeatures/todoSlice";



const AddTodo = () =>{

    const dispatch = useDispatch();
    const [input, setInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const todos = useSelector(state => state.todos);

    const addTodoHandler = (e) => {
        e.preventDefault();

        if (input.trim() === '') {
            setErrorMessage('Please enter your todo');
            return; // Exit the function without adding the todo
        }

        if (input.length > 19) {
            setErrorMessage('Input should not exceed 20 characters');
            return; // Exit the function without adding the todo
        }
        // Clear the error message
        setErrorMessage('');

        dispatch(addTodo(input));
        setInput('');
    }

    return (
        <div>
            <h2>Todo List</h2>
            <form onSubmit={addTodoHandler}>
                <input type="text" placeholder="Enter a todo" onChange={e => setInput(e.target.value)} value={input} maxLength={20}>
                </input>
                <button type="submit">Add</button>
                {errorMessage && <p style={{ color: 'red', marginRight: "20px" }}>{errorMessage}</p>}
            </form>
        </div>
    )
}

export default AddTodo;