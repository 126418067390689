import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import AddTodo from './components/AddTodo';
import RemoveTodo from './components/RemoveTodo';

function App() {
  return (
    <div className="App" style={{fontFamily: "sans-serif", background: "lightBlue", maxWidth: "100%", margin: "0 auto", border: "2px purple solid"}}>
      <AddTodo />
      <RemoveTodo />
    </div>
  );
}

export default App;
