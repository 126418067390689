import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
    list: [{id: 1, text: 'Hello'},]
}

export const todoSlice = createSlice({
    name: 'todos',
    initialState: initialState,
    reducers: {
        addTodo: (state, action) => {
            const todo = {
                id: nanoid(),
                text: action.payload,
            }
            state.list.push(todo);
            
        },
        removeTodo: (state, action) => {
            state.list = state.list.filter(todo => 
                todo.id !== action.payload
            )
            
        }
    }
})

export const {addTodo, removeTodo} = todoSlice.actions;
export default todoSlice.reducer;